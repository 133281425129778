import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavButtonComponent {
  @Input() label = '';
  @Input() img?: string;
  @Input() newWindow?: string;

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  get standardButton(): boolean {
    return !this.img;
  }

  onButtonClick() {
    if (this.newWindow) {
      window.open(this.newWindow, '_blank');
    } else {
    }
  }

  onMenuItemClick() {}
}
