import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {
      if (!environment.production) {
        const applicationRef = moduleRef.injector.get(ApplicationRef);
        const appComponent = applicationRef.components[0];
        enableDebugTools(appComponent);
      }
      return moduleRef;
    });

if (environment.hmr) {
} else {
  bootstrap().catch(err => console.log(err));
}
