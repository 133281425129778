import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MomentModule } from 'angular2-moment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material/material.module';
import { HttpClientModule } from '@angular/common/http';
import { NavButtonComponent } from './components/nav-button/nav-button.component';
import { HomeComponent } from './components/home/home.component';
import { ClanMemberStreamComponent } from './components/clan-member-stream/clan-member-stream.component';
import { DestinyPageComponent } from './components/pages/destiny-page/destiny-page.component';
import { RaidCardComponent } from './components/pages/destiny-page/raid-card/raid-card.component';

@NgModule({
  declarations: [
    AppComponent,
    NavButtonComponent,
    HomeComponent,
    ClanMemberStreamComponent,
    DestinyPageComponent,
    RaidCardComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, MaterialModule, HttpClientModule, MomentModule],
  exports: [],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
