import { IClanMember, PlatformType } from '../interfaces/members';

export const getMixerUrl = (username: string) => {
  return `https://mixer.com/embed/player/${username}?disableLowLatency=1&muted=true&disableCostream=true`;
};

export const getTwitchUrl = (username: string) => {
  return `https://player.twitch.tv/?channel=${username}?muted=true`;
};

export const getUrl = (member: IClanMember) => {
  if (member.platform === PlatformType.Mixer) return getMixerUrl(member.name);
  if (member.platform === PlatformType.Twitch) return getTwitchUrl(member.name);
};
