export enum DestinyGame {
  Destiny = 'destiny',
  Destiny2 = 'destiny2',
}

export enum DestinyLocation {
  Venus = 'Venus',
  Moon = 'Moon',
  Saturn = 'Saturn',
  Earth = 'Earth',
  Nessus = 'Nessus',
  Reef = 'Reef',
  BlackGarden = 'Black Garden',
}

export interface IDestinyRaidLocation {
  name: string;
  location: DestinyLocation;
  url?: string;
}

export interface IDestinyGuide {
  id: string;
  text: string;
}

export interface IDestinyRaid {
  description: string;
  destinyGame: DestinyGame;
  id: string;
  imageUrl?: string;
  location: IDestinyRaidLocation;
  name: string;
  releaseDate: number;
  guide?: IDestinyGuide;
}
