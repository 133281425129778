import { Component, ChangeDetectionStrategy } from '@angular/core';
import { RAIDS } from '../../../config/raids.config';
import { DestinyGame, IDestinyRaid } from '../../../interfaces/raid';

@Component({
  selector: 'app-destiny-page',
  templateUrl: './destiny-page.component.html',
  styleUrls: ['./destiny-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DestinyPageComponent {
  public destiny1Raids: IDestinyRaid[] = [];
  public destiny2Raids: IDestinyRaid[] = [];

  constructor() {
    this.destiny1Raids = RAIDS.filter(r => r.destinyGame === DestinyGame.Destiny);
    this.destiny2Raids = RAIDS.filter(r => r.destinyGame === DestinyGame.Destiny2);
  }
}
