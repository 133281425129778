import { Component, ChangeDetectionStrategy } from '@angular/core';
import { IGameListEntry } from './interfaces/games';
import * as config from './config/games.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public gamesList: IGameListEntry[] = config.gamesList;
}
