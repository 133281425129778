import { Component, ChangeDetectionStrategy } from '@angular/core';
import { clanMembers } from '../../config/members.config';
import { IClanMember } from '../../interfaces/members';
import { getUrl } from '../../utils/members.utils';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  public clanMembers: IClanMember[] = [];

  constructor() {
    this.clanMembers = clanMembers.map(mem => ({
      ...mem,
      url: getUrl(mem),
    }));
  }
}
