import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IDestinyRaid } from '../../../../interfaces/raid';

@Component({
  selector: 'app-raid-card',
  templateUrl: './raid-card.component.html',
  styleUrls: ['./raid-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RaidCardComponent {
  private _raid!: IDestinyRaid;
  public location = '';

  @Input()
  get raid(): IDestinyRaid {
    return this._raid;
  }
  set raid(value: IDestinyRaid) {
    this._raid = value;
    this.setLocationString();
  }

  private setLocationString() {
    const { raid } = this;
    const { location } = raid;
    const { name, location: planet } = location;
    this.location = name ? `${name}, ${planet}` : planet;
  }
}
