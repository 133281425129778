import { SafeUrl } from '@angular/platform-browser';

export enum PlatformType {
  Twitch = 'twitch',
  Mixer = 'mixer',
}

export interface IClanMember {
  name: string;
  platform: PlatformType;
  url?: string;
  safeUrl?: SafeUrl;
}
