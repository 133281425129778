import { PlatformType, IClanMember } from '../interfaces/members';

export const clanMembers: IClanMember[] = [
  {
    name: 'Ode_to_Gregg',
    platform: PlatformType.Mixer,
  },
  {
    name: 'TacticalFruit',
    platform: PlatformType.Twitch,
  },
  {
    name: 'TsunamicPenguin',
    platform: PlatformType.Mixer,
  },
  {
    name: 'als26',
    platform: PlatformType.Mixer,
  },
];
