import { Component, ChangeDetectionStrategy, Input, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IClanMember } from '../../interfaces/members';

const IFRAME_RATIO = 620 / 349;

@Component({
  selector: 'app-clan-member-stream',
  templateUrl: './clan-member-stream.component.html',
  styleUrls: ['./clan-member-stream.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClanMemberStreamComponent {
  private _member: IClanMember;
  get member() {
    return this._member;
  }
  @Input() set member(value: IClanMember) {
    this._member = {
      ...value,
      safeUrl: this._domSanitizer.bypassSecurityTrustResourceUrl(value.url),
    };
  }

  get streamTitle() {
    return this.member ? `${this.member.name}'s stream` : '';
  }

  get streamUrl() {
    return this.member ? this.member.safeUrl || this.member.url : '';
  }

  get iframeHeight() {
    const newHeight = IFRAME_RATIO * this._elemRef.nativeElement.width;
    return newHeight;
  }

  constructor(private _domSanitizer: DomSanitizer, private _elemRef: ElementRef) {}
}
